import React, { Component } from 'react';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import PaypalExpressBtn from 'react-paypal-express-checkout';
import SimpleReactValidator from 'simple-react-validator';

import Breadcrumb from '../common/breadcrumb';
import {
  removeFromWishlist,
  getFromPurchaseRefered,
  getFromCartUser,
} from '../../actions';
import { removeFromCart, incrementQty, decrementQty } from '../../actions';
import { removeToCart } from '../../actions';
import { getCartTotal } from '../../services';
import AuthService from '../services/auth.service';
import ApiService from '../services/api.service';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Calendar from '../common/calendar';
import Moment from 'moment';
import store from '../../store';
import Swal from 'sweetalert2';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import VerThanks from '../common/ver-thanks';
import UploadVoucher from '../upload-voucher';

let checkout_items = [];
class UserCheckOut extends Component {
  selectRef = null;
  constructor(props) {
    super(props);
    this.handlePurchase = this.handlePurchase.bind(this);
    this.selectDate = this.selectDate.bind(this);
    this.onChangeCash = this.onChangeCash.bind(this);
    this.gotoHome = this.gotoHome.bind(this);
    this.state = {
      payment: 'stripe',
      first_name: '',
      last_name: '',
      phone: '',
      email: '',
      country: '',
      address: '',
      city: '',
      state: '',
      pincode: '',
      create_account: '',
      cash_amount: 0,
      show_input_cash: true,
      selectedAddressOption: null,
      errors: [
        {
          address: '',
        },
        {
          hour: '',
        },
        {
          cash: false,
        },
      ],
      data_address: [],
      total_order: 0,
      selectedHourOption: null,
      data_hour: [
        /*{value: '10:00',label: '10:00', key: 0},
                {value: '12:30',label: '12:30', key: 1},
                {value: '15:30',label: '15:30', key: 2}*/
      ],
      data_orders: [],
      date: null,
      cash: 0,
      previous_cash: 0,
      new_cash: 0,
      cash_win: 0,
      showThanks: false,
      cost_delivery: 0,
      old_cost_delivery: 0,
      minimum_amount: 0,
      maximum_amount_no_delivery: 0,
      new_orderId: 0,
      showModal: 'none',
      uploadVoucher: '',
      isCheckedDeferredPay: false,
      deferred_pay: '',
      uniqueId: '',
      haveVoucher: '',
      show_used_credit: false,
      total_order_visual: 0
    };

    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleShowModal = this.handleShowModal.bind(this);
    this.handleVoucherSubmit = this.handleVoucherSubmit.bind(this);
    this.validator = new SimpleReactValidator();
    this.data_hour = [];
  }


  handleCheckboxChange = () => {
    this.setState((prevState) => {
        const newCheckedState = !prevState.isCheckedDeferredPay;

        // Completa el campo hidden si se marca
        return {
            isCheckedDeferredPay: newCheckedState,
            deferred_pay: newCheckedState ? 'DIFERIDO' : 'REVISAR-VOUCHER'
        };
    });
  };

  handleShowModal = () => {
    // Llama a setState y luego ejecuta el callback para ver el estado actualizado
    this.setState({ showModal: 'block' }, () => {
      console.log('After', this.state.showModal); // Aquí obtendrás el valor actualizado
    });
    
    console.log('Before', this.state.showModal); // Este mostrará el valor antes de la actualización
  };
    
  handleCloseModal = () => {
    this.setState({ showModal: 'none' }, () => {
        console.log('Modal cerrado, estado actual:', this.state.showModal);
    });
  };

  generateUniqueId = () => {
    return `VP${Math.random().toString(36).substr(2, 9)}-${Date.now()}`;
  };

  handleVoucherSubmit = async (acceptedFiles) => {
      
      const newId = this.generateUniqueId();
      this.setState({ uniqueId: newId });

      const formData = new FormData();
      const user = AuthService.getCurrentUser();
      formData.append('file', acceptedFiles[0]); // Ajusta según tus necesidades
      formData.append('purchase_id', this.state.uniqueId);

      try {
          const response = await ApiService.uploadVoucherTemp(user.token, formData);

          const voucherId = response.data;

          // Actualizamos el estado 'haveVoucher' con el valor del voucher
          this.setState({ haveVoucher: voucherId });
          
          Swal.fire({
              icon: 'success',
              title: 'Hecho!',
              text: 'Voucher subido correctamente.'
          });

          this.handleCloseModal(); // Cerrar el modal después de subir el voucher

          } catch (error) {
          console.error(error);
          Swal.fire({
              icon: 'error',
              title: 'Error!',
              text: 'Hubo un problema al subir el voucher.'
          });
      }

  };

  componentWillMount() {
    var user = AuthService.getCurrentUser();
    if (user) {
      document.body.style = `background-color: #161616;font-family: 'Work Sans'`;
      this.checkOpen();
    } else {
      this.props.history.push('/login');
    }
  }

  checkOpen() {
    var user = AuthService.getCurrentUser();
    ApiService.checkOpen(user.token).then(
      (res) => {
        if (res && res.status == 'success') {
          //console.log('res.data',res.data);
          if (res.data.option_value == 'NO') {
            if (window.location.pathname != '/closed') {
              this.props.history.push('/closed');
            }
          } else {
            this.getCheckoutSettings();
            this.getRefOrders();
            this.getUserAddresses();
            this.setDataOrder();

            //this.setDataOrder();
            var today = Date.now();
            let dia_id = Moment(today).format('d');
            dia_id = dia_id != 0 ? dia_id : 7;
            this.getPickupWindows(dia_id, 1);
            this.setState({ date: Moment(today).format('YYYY-MM-DD') });
            this.getCash();
          }
        } else {
          this.props.history.push('/closed');
          window.location.reload();
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  }

  componentDidUpdate(prevProps, prevState) {
    /*if(this.props.cartItems != prevProps.cartItems){
            this.setDataOrder();
        }*/
  }

  getRefOrders() {
    var user = AuthService.getCurrentUser();
    ApiService.getReferredOrders(user.token, user.token_order, user.id).then(
      (res) => {
        if (res && res.status == 'success') {
          //console.log('data',res.data);

          store.dispatch(getFromPurchaseRefered(res.data));
          store.dispatch(getFromCartUser(res.carts_data));

          //this.setState({ order_results:res.data });
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  }

  onChangeCash = (e) => {
    const value = parseFloat(e.target.value) || 0; // Asegura que siempre haya un valor numérico
    this.setState({
      cash_amount: value,
      errors: { cash: value > this.state.cash }, // Valida si supera el crédito disponible
    });
  };
  
  show_input = () => {
    const { show_input_cash, cash_amount, cash, total_order, cost_delivery } = this.state;
  
    if (show_input_cash) {
      // Validar que el crédito aplicado sea válido
      if (cash_amount > cash || cash_amount <= 0) {
        this.setState({
          errors: { cash: true },
        });
      } else {
        // Actualizar el total y el estado
        const updatedTotal = total_order - cash_amount + parseFloat(cost_delivery);
  
        this.setState(
          {
            total_order_visual: updatedTotal, // Actualizar total visual
            show_used_credit: true, // Mostrar crédito usado
            show_input_cash: false, // Ocultar botón y campo de entrada
            errors: { cash: false }, // Limpiar errores
          },
          () => {
            this.updateTotalVisual(); // Recalcular el total visual después de aplicar el crédito
          }
        );
      }
    } else {
      // Mostrar el input para ingresar crédito
      this.setState({ show_input_cash: true });
    }
  };
  
  // Función para recalcular el total visual
  updateTotalVisual = () => {
    const { cash_amount, total_order, cost_delivery } = this.state;
    const updatedTotal = total_order + parseFloat(cost_delivery) - cash_amount;
    this.setState({ total_order_visual: updatedTotal });
  };
  
  

  gotoHome() {
    /*document.getElementById("ver-section-buy").style.display = "none";
        document.getElementById("verThanks").style.display = "block";*/
    this.setState({
      showThanks: true,
    });
  }
  handlePurchase(e) {
    if (this.state.data_orders.length > 0) {
      if (!this.state.selectedAddressOption) {
        this.setState({
          errors: { address: 'Error' },
        });
        return true;
      } else {
        this.setState({
          errors: { address: '' },
        });
      }

      if (!this.state.selectedHourOption) {
        this.setState({
          errors: { hour: 'Error' },
        });
        return true;
      } else {
        this.setState({
          errors: { hour: '' },
        });
      }

      if (this.state.cash_amount > this.state.cash) {
        this.setState({
          errors: { cash: true },
        });
        return true;
      } else {
        this.setState({
          errors: { cash: false },
        });
      }

      let pago_total = this.state.total_order;

      console.log(pago_total);

      if (pago_total < this.state.minimum_amount) {
        Swal.fire({
          showConfirmButton: false,
          type: 'warning',
          title: 'Aviso!',
          text: 'El monto mínimo es de ' + this.state.minimum_amount,
          timer: 5000,
        });
        return false;
      }

      this.setState({
        loading: true,
      });

      var user = AuthService.getCurrentUser();

      let hour_programmed = this.data_hour.find(
        (x) => x.id == this.state.selectedHourOption.value
      );


      let data_checkout = {
        token: user.token_order,
        id_user: user.id,
        date_programmed: this.state.date,
        hour_programmed_start: hour_programmed.start_time,
        hour_programmed_end: hour_programmed.end_time,
        id_address: this.state.selectedAddressOption.value,
        cash: this.state.cash_amount ? this.state.cash_amount : 0,
        deferred_pay: this.state.deferred_pay,
        purchase_v: this.state.haveVoucher ? this.state.haveVoucher : ''
        //'purchases': purchases,
      };
      //console.log('data_checkout', data_checkout);

      //return false
      document.getElementsByClassName('page-loader')[0].style.display = 'flex';

      ApiService.updatePurchaseUser(user.token, data_checkout).then(
        (res) => {
          document.getElementsByClassName('page-loader')[0].style.display =
            'none';
          if (res && res.status == 'success') {
            localStorage.removeItem('orders_selected');
            this.getRefOrders();
            this.getNewCash();

            if (this.state.cash_amount > 0) {
              Swal.fire({
                showConfirmButton: false,
                type: 'success',
                title: 'Hecho!',
                text: 'Tu crédito se aplicó correctamente!',
                timer: 2000,
              });
              setTimeout(this.gotoHome, 1000);
              //this.props.history.push("/thanks");
            } else {
              /*document.getElementById("ver-section-buy").style.display = "none";
                            document.getElementById("verThanks").style.display = "block";*/
              this.setState({
                showThanks: true,
              });
            }
          } else {
            if (res.mensajeError) {
              var message_error = res.mensajeError;
            } else {
              var message_error = res.message;
            }

            Swal.fire({
              showConfirmButton: false,
              type: 'warning',
              title: 'Aviso!',
              html: message_error,
            });

            this.setState({
              loading: false,
            });
          }
        },
        (error) => {
          document.getElementsByClassName('page-loader')[0].style.display =
            'none';
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.setState({
            loading: false,
          });
        }
      );
    }
  }

  selectDate(date) {
    //console.log(Moment(date).format('YYYY-MM-DD'));
    //console.log(Moment(date).format('d'));
    let newDate = new Date();
    let current_date = Moment(newDate).format('YYYY-MM-DD');
    let dia_id = Moment(date).format('d');
    dia_id = dia_id != 0 ? dia_id : 7;
    let select_date = Moment(date).format('YYYY-MM-DD');
    this.setState({ date: Moment(date).format('YYYY-MM-DD') });
    if (current_date == select_date) {
      this.getPickupWindows(dia_id, 1);
    } else {
      this.getPickupWindows(dia_id, 0);
    }

    this.selectRef.select.clearValue();
  }

  getPickupWindows(id, flag) {
    var user = AuthService.getCurrentUser();
    ApiService.getPickupWindows(user.token, id, flag).then(
      (res) => {
        if (res && res.status == 'success') {
          this.data_hour = res.data;
          //console.log('address',res.data);
          //console.log('this.data_hour',this.data_hour);

          /*
                    data_hour: [
                        {value: '10:00',label: '10:00', key: 0},
                        {value: '12:30',label: '12:30', key: 1},
                        {value: '15:30',label: '15:30', key: 2}
                    ],
                    */
          var items = res.data.map(function(row) {
            //console.log('row atención',row);
            var start_time = row.start_time.toString();
            var end_time = row.end_time.toString();
            //return { value: row.id, label : Moment("0 "+row.start_time).format('HH:mm')+' - '+Moment("0 "+row.end_time).format('HH:mm'), key: row.id}
            return {
              value: row.id,
              label: start_time.slice(0, -3) + ' - ' + end_time.slice(0, -3),
              key: row.id,
            };
            //return { value: row.id, label : row.start_time+' - '+row.end_time, key: row.id}
          });
          //console.log(items);
          this.setState({ data_hour: items });
          this.renderResults();

          if (items.length == 0) {
            Swal.fire({
              //showConfirmButton: false,
              type: 'warning',
              //title: 'Alerta!',
              text: 'No hay horario disponible en la fecha seleccionada!',
              //timer: 2000
            });
          }
          //console.log(this.state.data_address)
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  }

  setDataOrder() {
    var user = AuthService.getCurrentUser();
    ApiService.getCartUser(user.token, user.id).then(
      (res) => {
        if (res && res.status === 'success') {
          checkout_items = res.data;
          if (checkout_items.length === 0 && this.state.showThanks === false) {
            this.props.history.push('/welcome');
          }
  
          this.setState({ data_orders: checkout_items }, function () {
            let total = 0;
            this.state.data_orders.forEach(function (row) {
              total += row.quantity * row.price;
            });
            // Establecer el total inicial de la orden
            this.setState({ total_order: total }, () => {
              // Calcular el total visual después de establecer el total de la orden
              const updatedTotal = this.state.total_order + this.state.cost_delivery;
              this.setState({ total_order_visual: updatedTotal });
            });
          });
  
          if (this.state.total_order >= this.state.maximum_amount_no_delivery) {
            this.setState({ cost_delivery: 0 });
          } else {
            this.setState({ cost_delivery: this.state.old_cost_delivery });
          }
        }
      },
      (error) => {
        const resMessage =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  }
  
  

  getCheckoutSettings() {
    var user = AuthService.getCurrentUser();
    ApiService.getCheckoutSettings(user.token).then(
      (res) => {
        if (res && res.status == 'success') {
          //console.log('settings',res.data);
          let data = res.data;
          this.setState({
            minimum_amount: data.minimum_amount.option_value,
            maximum_amount_no_delivery:
              data.maximum_amount_no_delivery.option_value,
          });
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  }

  getUserAddresses() {
    var user = AuthService.getCurrentUser();
    ApiService.getUserAddresses(user.token, user.id).then(
      (res) => {
        if (res && res.status == 'success') {
          //console.log('address',res.data);
          this.items = res.data.map(function(row) {
            return {
              value: row.id,
              label: row.type_address + ' ' + row.address + ' ' + row.number,
              key: row.id,
              data: row,
            };
          });
          this.setState({ data_address: this.items });
          //console.log(this.state.data_address)
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  }

  setStateFromInput = (event) => {
    var obj = {};
    obj[event.target.name] = event.target.value;
    this.setState(obj);
  };

  setStateFromCheckbox = (event) => {
    var obj = {};
    obj[event.target.name] = event.target.checked;
    this.setState(obj);

    if (!this.validator.fieldValid(event.target.name)) {
      this.validator.showMessages();
    }
  };

  checkhandle(value) {
    this.setState({
      payment: value,
    });
  }

  StripeClick = () => {
    if (this.validator.allValid()) {
      alert('You submitted the form and stuff!');

      var handler = window.StripeCheckout.configure({
        key: 'pk_test_glxk17KhP7poKIawsaSgKtsL',
        locale: 'auto',
        token: (token) => {
          //console.log(token)
          this.props.history.push({
            pathname: '/order-success',
            state: {
              payment: token,
              items: this.props.cartItems,
              orderTotal: this.props.total,
              symbol: this.props.symbol,
            },
          });
        },
      });
      handler.open({
        name: 'Multikart',
        description: 'Online Fashion Store',
        amount: this.amount * 100,
      });
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      this.forceUpdate();
    }
  };

  handleAddress = (selectedAddressOption) => {
    // Actualizamos la dirección seleccionada y los costos de envío
    this.setState(
      {
        selectedAddressOption,
        cost_delivery: selectedAddressOption.data.delivery
          ? selectedAddressOption.data.delivery
          : 0,
        old_cost_delivery: selectedAddressOption.data.delivery
          ? selectedAddressOption.data.delivery
          : 0,
      },
      () => {
        // Aquí el estado ha sido actualizado, así que recalculamos el total
        if (this.state.total_order >= this.state.maximum_amount_no_delivery) {
          this.setState(
            {
              cost_delivery: 0,
            },
            () => {
              this.updateTotalVisual(); // Recalcular total_order_visual
            }
          );
        } else {
          this.setState(
            {
              cost_delivery: selectedAddressOption.data.delivery
                ? selectedAddressOption.data.delivery
                : 0,
            },
            () => {
              this.updateTotalVisual(); // Recalcular total_order_visual
            }
          );
        }
      }
    );
  };
  

  handleHour = (selectedHourOption) => {
    this.setState({ selectedHourOption }, () => console.log(''));
  };

  WatchOrder = (item) => {
    //console.log(item)
    for (let i = 0; i < checkout_items.length; i++) {
      checkout_items[i]['visible'] = 0;
    }
    let index = checkout_items.findIndex((x) => x.id === item.id);
    //console.log('index',index);
    checkout_items[index].visible = 1;
    this.setState({ data_orders: checkout_items });
  };

  incrementQty = (item) => {
    //console.log('incrementQty item', item);
    var user = AuthService.getCurrentUser();
    var cart_quantity = item.quantity + 1;
    let data_cart = {
      id_cart: item.id,
      token: user.token_order,
      id_user: user.id,
      id_product: item.id_product,
      quantity: cart_quantity,
      price: item.price,
    };

    ApiService.updateCartUser(user.token, data_cart).then(
      (res) => {
        if (res && res.status == 'success') {


          store.dispatch(incrementQty(data_cart.id_cart));
          var new_data = this.state.data_orders.map(function(row) {
            
            return {
              id: row.id,
              id_product: row.id_product,
              id_user: row.id_user,
              price: row.price,
              quantity: row.id === item.id ? row.quantity + 1 : row.quantity,
              token: row.token,
              Product: {
                url: row.Product.url,
                name: row.Product.name,
                logo: row.Product.logo,
              },
            };
          });

          this.setDataOrder();

        } else {
          if (res.mensajeError) {
            Swal.fire({
              showConfirmButton: false,
              type: 'warning',
              title: 'Aviso!',
              html: res.mensajeError,
            });
          } else if (res.message) {
            Swal.fire({
              showConfirmButton: false,
              type: 'warning',
              title: 'Aviso!',
              html: res.message,
            });
          }
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
    //console.log('new_checkout_items',checkout_items);
  };

  decrementQty = (item) => {
    var user = AuthService.getCurrentUser();
    var cart_quantity = item.quantity - 1;
    if (cart_quantity == 0) {
      return true;
    }
    let data_cart = {
      id_cart: item.id,
      token: user.token_order,
      id_user: user.id,
      id_product: item.id_product,
      quantity: cart_quantity,
      price: item.price,
    };

    ApiService.updateCartUser(user.token, data_cart).then(
      (res) => {
        if (res && res.status == 'success') {
          //console.log('updateCartUser',data_cart);
          store.dispatch(decrementQty(data_cart.id_cart));
          var new_data = this.state.data_orders.map(function(row) {
            return {
              id: row.id,
              id_product: row.id_product,
              id_user: row.id_user,
              price: row.price,
              quantity:
                row.id === item.id && row.quantity > 1
                  ? row.quantity - 1
                  : row.quantity,
              token: row.token,
              Product: {
                url: row.Product.url,
                name: row.Product.name,
                logo: row.Product.logo,
              },
            };
          });

          this.setDataOrder();

        } else {
          if (res.mensajeError) {
            Swal.fire({
              showConfirmButton: false,
              type: 'warning',
              title: 'Aviso!',
              html: res.mensajeError,
            });
          } else if (res.message) {
            Swal.fire({
              showConfirmButton: false,
              type: 'warning',
              title: 'Aviso!',
              html: res.message,
            });
          }
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  };

  showConfirmarPedido() {
    var nextView = false;
    if (!this.state.selectedAddressOption) {
      this.setState({
        errors: { address: 'Error' },
      });
      nextView = false;
      return true;
    } else {
      this.setState({
        errors: { address: '' },
      });
      nextView = true;
    }

    if (!this.state.selectedHourOption) {
      this.setState({
        errors: { hour: 'Error' },
      });
      nextView = false;
      return true;
    } else {
      this.setState({
        errors: { hour: '' },
      });
      nextView = true;
    }

    //console.log('nextView', nextView)
    if (nextView == true) {
      document.getElementById('verDireccionHora').style.display = 'none';
      document.getElementById('verConfirmarPedido').style.display = 'block';
    }
  }

  showDireccionHora() {}


  removeFromCartUpdate = (item) => {
  
    if (item && item.id > 0 && item.Product) {
      Swal.fire({
        title: 'Confirmar',
        text: '¿Desea eliminar el producto ' + item.Product.name + '?',
        //type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.value) {
          var user = AuthService.getCurrentUser();
          ApiService.deleteCartUser(user.token, item.id).then(
            (res) => {
              if (res && res.status == 'success') {
                store.dispatch(removeFromCart(item));
                this.getRefOrders();
                this.setDataOrder();
                Swal.fire({
                  type: 'success',
                  title: 'Hecho!',
                  text: 'Producto Eliminado!',
                  timer: 1500,
                });
              } else {
                if (res.mensajeError) {
                  Swal.fire({
                    showConfirmButton: false,
                    type: 'warning',
                    title: 'Aviso!',
                    html: res.mensajeError,
                  });
                } else if (res.message) {
                  Swal.fire({
                    showConfirmButton: false,
                    type: 'warning',
                    title: 'Aviso!',
                    html: res.message,
                  });
                }
              }
            },
            (error) => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
            }
          );
        }
      });
    }
  };

  getCash() {
    var user = AuthService.getCurrentUser();
    AuthService.getCash(user.token).then(
      (res) => {
        if (res && res.status == 'success') {
          //console.log('getCash data',res.data);
          let cash_amount =
            res && res.data && res.data.amount_cash
              ? Math.round(res.data.amount_cash * 100) / 100
              : 0;
          //this.setState({ cash_amount: cash_amount });
          this.setState({ cash: cash_amount });
          this.setState({ previous_cash: cash_amount });

          //console.log(this.state.data_categ_parents);
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  }

  getNewCash() {
    var user = AuthService.getCurrentUser();
    AuthService.getCash(user.token).then(
      (res) => {
        if (res && res.status == 'success') {
          //console.log('getCash data',res.data);
          let cash_amount =
            res && res.data && res.data.amount_cash
              ? Math.round(res.data.amount_cash * 100) / 100
              : 0;
          //this.setState({ cash_amount: cash_amount });
          this.setState({ cash: cash_amount });
          this.setState({ new_cash: cash_amount });
         
          //este es el cash win nuevo
            var cash_win =
            ((this.state.previous_cash + 0.2*(this.state.cash_amount)) - this.state.new_cash) *-1;  
          //console.log(cash_win)
          this.setState({
            cash_win: Math.round(cash_win * 100) / 100,
          });

        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  }

  renderResults() {
    //console.log('renderResults()',this.state.data_hour);
    const { selectedHourOption } = this.state;
    const customStyles = {
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        // const color = chroma(data.color);
        //console.log({ data, isDisabled, isFocused, isSelected });
        return {
          ...styles,
          backgroundColor: isFocused ? '#161616' : '#161616',
          color: isFocused ? '#F8EEE1' : '#F8EEE1',
        };
      },
      placeholder: (defaultStyles) => {
        return {
          ...defaultStyles,
          position: 'absolute',
          display: 'inherit',
          paddingLeft: '2px',
        };
      },
      control: (base, state) => ({
        ...base,
        background: '#161616',
        //opacity: 0.5,
        border: '2px solid #F8EEE1',
        //color: "#cfd4da",
        borderRadius: 30,
        backgroundColor: state.isFocused ? '#161616' : null,

      }),
      singleValue: (base, state) => ({
        ...base,
        color: '#F8EEE1',
        position: 'absolute',
        display: 'inherit',
        paddingLeft: '2px',
      }),
      input: (base, state) => ({
        ...base,
        position: 'absolute',
        color: '#F8EEE1',
      }),
      valueContainer: (base, state) => ({
        ...base,
        minHeight: 'inherit',
      }),
    };

    return (
      <Select
        ref={(ref) => {
          this.selectRef = ref;
        }}
        value={selectedHourOption}
        onChange={this.handleHour}
        options={this.state.data_hour}
        placeholder={'Seleccione'}
        styles={customStyles}
      />
    );
  }

  render() {
    const { cartItems, total } = this.props;
    const symbol = (
      <img
        src={`${process.env.PUBLIC_URL}/assets/images/icon/credit.png`}
        alt=""
        className="img-fluid"
        style={{
          width: 'auto',
          height: '20px',
          bottom: '2px'
        }}
      />
    );

    const symbolUpload = (
      <img
        src={`${process.env.PUBLIC_URL}/assets/images/icon/upload.svg`}
        alt=""
        className="img-fluid"
        style={{
          width: 'auto',
          height: '20px',
          bottom: '2px'
        }}
      />
    );

    document.body.classList.add('dark');

    // Paypal Integration
    const onSuccess = (payment) => {
      //console.log("The payment was succeeded!", payment);
      this.props.history.push({
        pathname: '/order-success',
        state: {
          payment: payment,
          items: cartItems,
          orderTotal: total,
          symbol: symbol,
        },
      });
    };

    const onCancel = (data) => {
      //console.log('The payment was cancelled!', data);
    };

    const onError = (err) => {
      //console.log("Error!", err);
    };

    const client = {
      sandbox:
        'AZ4S98zFa01vym7NVeo_qthZyOnBhtNvQDsjhaZSMH-2_Y9IAJFbSD3HPueErYqN8Sa8WYRbjP7wWtd_',
      production:
        'AZ4S98zFa01vym7NVeo_qthZyOnBhtNvQDsjhaZSMH-2_Y9IAJFbSD3HPueErYqN8Sa8WYRbjP7wWtd_',
    };

    const { selectedAddressOption } = this.state;
    //const { selectedHourOption } = this.state;
    const customStyles = {
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        // const color = chroma(data.color);
        //console.log({ data, isDisabled, isFocused, isSelected });
        return {
          ...styles,
          backgroundColor: isFocused ? '#161616' : '#161616',
          color: isFocused ? '#F8EEE1' : '#F8EEE1',
        };
      },
      placeholder: (defaultStyles) => {
        return {
          ...defaultStyles,
          position: 'absolute',
          display: 'inherit',
          paddingLeft: '2px',
        };
      },
      control: (base, state) => ({
        ...base,
        background: '#161616',
        border: '2px solid #F8EEE1',
        borderRadius: 30,
        backgroundColor: state.isFocused ? '#161616' : null,
      }),
      singleValue: (base, state) => ({
        ...base,
        color: '#F8EEE1',
        position: 'absolute',
        display: 'inherit',
        paddingLeft: '2px',
      }),
      input: (base, state) => ({
        ...base,
        position: 'absolute',
        color: '#F8EEE1',
      }),
      valueContainer: (base, state) => ({
        ...base,
        minHeight: 'inherit',
      }),
    };

    return (
      <div
        className="cart-container ref-chk-container"
        style={{ backgroundColor: '#161616 !important' }}
      >
        
        {this.state.showThanks == false ? (
          <section id="ver-section-buy" className="section-b-space">
            <div className="container padding-cls">
              <div className="checkout-page">
                <div className="checkout-form">
                  <form>
                    <div className="checkout row">
                      {
                        <div
                          id="verDireccionHora"
                          className="col-lg-12 col-sm-12 col-xs-12" style={{display: 'none'}}
                        >
                          <div style={{ marginBottom: '1.5rem' }}>
                            <div
                              className="row"
                              style={{ marginBottom: '1.5rem' }}
                            >
                              <div className="col-md-6 col-sm-6 offset-md-3 offset-sm-3 col-xs-6 offset-xs-3">
                                <div
                                  className="page-title page-tittle2"
                                  style={{ textAlign: 'center' }}
                                >
                                  {/*<h1>{title}</h1>*/}
                                  <label className="text-confirm-order">
                                    ELIGE DIRECCIÓN, DÍA Y HORA DE ENTREGA
                                  </label>
                                </div>
                              </div>
                            </div>

                            <div
                              className="referred-checkout-title"
                              style={{ textAlign: 'center' }}
                            >
                              <label>Tus direcciones</label>
                            </div>
                            <div className="row check-out">
                              <div
                                className="form-group col-md-4 col-sm-4 col-xs-12"
                                style={{ margin: '0 auto' }}
                              >
                                {/*<div className="field-label">Dirección</div>*/}
                                <Select
                                  value={selectedAddressOption}
                                  onChange={this.handleAddress}
                                  options={this.state.data_address}
                                  placeholder={'Seleccione'}
                                  styles={customStyles}
                                />
                                {this.state.errors.address &&
                                !this.state.selectedAddressOption ? (
                                  <div
                                    className="alert alert-danger ver-alert"
                                    role="alert"
                                  >
                                    Este campo es obligatorio!
                                  </div>
                                ) : (
                                  ''
                                )}
                                {/*<input type="text" name="address" value={this.state.address} onChange={this.setStateFromInput} placeholder="Street address" />
                                                        {this.validator.message('address', this.state.address, 'required|min:20|max:120')}*/}
                              </div>
                            </div>
                          </div>
                          <div style={{ marginBottom: '1.5rem' }}>
                            <div
                              className="referred-checkout-title"
                              style={{ textAlign: 'center' }}
                            >
                              <label>DÍA</label>
                            </div>
                            <div>
                              <div
                                className="row check-out row-check-date"
                                style={{ textAlign: 'center' }}
                              >
                                <div
                                  className="form-group col-md-4 col-sm-4 col-xs-12"
                                  style={{ margin: '0 auto' }}
                                >
                                  {/*<div className="field-label">Fecha</div>*/}
                                  <Calendar
                                    handleDateChange={this.selectDate}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div
                              className="referred-checkout-title"
                              style={{ textAlign: 'center' }}
                            >
                              <label>HORA</label>
                            </div>
                            <div className="row check-out ">
                              <div
                                className="form-group col-md-4 col-sm-4 col-xs-12"
                                style={{ margin: '0 auto' }}
                              >
                                {/*<div className="field-label">Hora</div>*/}
                                {this.renderResults()}
                                {this.state.errors.hour &&
                                !this.state.selectedHourOption ? (
                                  <div
                                    className="alert alert-danger ver-alert"
                                    role="alert"
                                  >
                                    Este campo es obligatorio!
                                  </div>
                                ) : (
                                  ''
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="row chk-cart-buttons">
                            <div className="col-12 cart-btn-checkout">
                              <button
                                type="button"
                                className="btn btn-solid btn-referred-checkout"
                                disabled={this.state.loading}
                                onClick={() => this.showConfirmarPedido()}
                              >
                                CONFIRMAR
                              </button>
                            </div>
                          </div>
                        </div>
                      }

                      {
                        <div
                          id="verConfirmarPedido"
                          className="col-lg-12 col-sm-12 col-xs-12 ref-container-checkout-details"
                          style={{ display: 'block' }}
                        >
                        
                        <div style={{marginBottom: '1.5rem'}}>

                        <div className="row" style={{marginBottom: '1.5rem'}}>
                          <div className="col-md-6 col-sm-6 offset-md-3 offset-sm-3 col-xs-6 offset-xs-3" >
                              <div className="page-title page-tittle2" style={{textAlign: 'left'}}>
                                  
                                  <label className="text-confirm-order">1- DATOS DE ENVIO</label>
                              </div>
                          </div>
                      </div>
                      

                      <div className='container cont_lugar'>
                                    <div className="row">
                                        <div className="col-2" style={{marginRight: '-16px', marginTop: '0.4rem'}}>
                                            <img src="/assets/images/icon/geo-alt (1).png" alt="" className="img-fluid" />
                                        </div>
                                        <div className="col-3 referred-checkout-title" style={{textAlign: 'left', paddingLeft: '0', marginTop: '0.5rem' }}>
                                            <label>¿Lugar?</label>
                                        </div>
                                        <div className="col-7 check-out">
                                            <div className="form-group" style={{margin: '0 auto'}}>
                                            <Select
                                              value={selectedAddressOption}
                                              onChange={this.handleAddress}
                                              options={this.state.data_address}
                                              placeholder={'Seleccione'}
                                              styles={customStyles}
                                            />
                                                    {
                                                    this.state.errors.address && !this.state.selectedAddressOption?
                                                    <div className="alert alert-danger ver-alert" role="alert" >Este campo es obligatorio!</div>
                                                    :''}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                      
                      </div>

                      <div className="container cont_dia" style={{marginBottom: '1.5rem'}}>
                                    <div className="row">
                                        <div className="col-2" style={{marginRight: '-16px', marginTop: '0.4rem'}}>
                                            <img src="/assets/images/icon/calendar.png" alt="" className="img-fluid" />
                                        </div>
                                        <div className="col-3 referred-checkout-title"  style={{textAlign: 'left', paddingLeft: '0', marginTop: '0.5rem' }}>
                                            <label>¿Día?</label>
                                        </div>
                                        <div className="col-7">
                                            <div className="row check-out row-check-date" style={{textAlign: 'left'}}>
                                                <div className="form-group" style={{margin: '0 auto'}}>
                                                    <Calendar handleDateChange={this.selectDate}/>
                                                </div>
                                            </div>                                            
                                        </div>
                                    </div>
                                </div>
                      
                      <div className="container cont_hora" style={{marginBottom: '1.5rem'}}>
                                    <div className="row">
                                        <div className="col-2" style={{marginRight: '-16px', marginTop: '0.4rem'}}>
                                            <img src="/assets/images/icon/clock.png" alt="" className="img-fluid" />
                                        </div>
                                        <div className="col-3 referred-checkout-title"  style={{textAlign: 'left', paddingLeft: '0', marginTop: '0.5rem' }}>
                                            <label>¿Hora?</label>
                                        </div>
                                        <div className="col-7 check-out">
                                                <div className="form-group" style={{margin: '0 auto'}}>
                                                {this.renderResults()}
                                                    {
                                                    this.state.errors.hour && !this.state.selectedHourOption?
                                                    <div className="alert alert-danger ver-alert" role="alert" >Este campo es obligatorio!</div>
                                                    :''}
                                                </div>
                                        </div>
                                    </div>
                                </div>

                        <div className="row">
                          <div className="col-md-8 col-sm-8 offset-md-2 offset-sm-2 col-xs-8 offset-xs-2">
                              <div className="page-title page-tittle2" style={{textAlign: 'left'}}>
                                          
                                  <label className="text-confirm-order">2- REVISA TU ORDEN</label>
                              </div>
                          </div>
                      </div>
                         
                          <div
                            className="checkout-details ref-checkout-details data-ref-items-container"
                            style={{
                              marginBottom: '1.5rem',
                              marginTop: '1.5rem',
                            }}
                          >
                            <div className="order-box checkout-order-box">
                           
                              <ul className="qty">
                                {this.state.data_orders.map((item, index) => {
                                  const subtotal = (item.price * item.quantity).toFixed(2);
                                  return (
                                    <div key={index}>
                                      {
                                        /*item.Purchases_detail.map((sub_item, subindex) =>*/
                                        <div>
                                          <div className="col-xs-3 div-checkout-remove">
                                            <h2 className="td-color">
                                              <a
                                                className="icon checkout-remove"
                                                onClick={() =>
                                                  this.removeFromCartUpdate(
                                                    item
                                                  )
                                                }
                                              >
                                                <i className="icon-close"></i>
                                              </a>
                                            </h2>
                                          </div>
                                          <div
                                            key={`sub_item_name` + index}
                                            className="chk_sub_item_container"
                                          >
                                            <div className="chk_sub_item_img">
                                              <img
                                                src={
                                                  `${process.env.REACT_APP_URL_FILES}images/products/` +
                                                  (item &&
                                                  item.Product &&
                                                  item.Product.url
                                                    ? item.Product.url
                                                    : item && item.Product.logo
                                                    ? item.Product.logo
                                                    : '')
                                                }
                                                className="sub_item_img_checkout"
                                              />
                                            </div>
                                            
                                            <div className="chk_sub_item_details">
                                              <div className="chk_sub_item_name">
                                                <label>
                                                  {item &&
                                                  item.Product &&
                                                  item.Product.name
                                                    ? item.Product.name
                                                    : item && item.name
                                                    ? item.name
                                                    : ''}{' '}
                                                  {item &&
                                                  item.Product &&
                                                  item.Product.size
                                                    ? ' - ' + item.Product.size
                                                    : ''}
                                                </label>
                                               

                                                <div className="qty-box box-quantity chk-qty">
                                                  <div className="input-group" 
                                                  style={{ flexWrap: 'nowrap' }}
                                                  >
                                                    <span className="input-group-prepend">
                                                      <button
                                                        type="button"
                                                        className="btn quantity-left-minus btn-checkout-minus"
                                                        onClick={() =>
                                                          this.decrementQty(
                                                            item
                                                          )
                                                        }
                                                        data-type="minus"
                                                        data-field=""
                                                      >
                                                        <i className="fa fa-minus"></i>
                                                      </button>
                                                    </span>
                                                    <input
                                                      type="text"
                                                      name="quantity"
                                                      value={item.quantity}
                                                      readOnly={true}
                                                      className="form-control input-number chk_sub_item_input input-checkout"
                                                    />

                                                    <span className="input-group-prepend">
                                                      <button
                                                        type="button"
                                                        className="btn quantity-right-plus btn-checkout-plus"
                                                        onClick={() =>
                                                          this.incrementQty(
                                                            item
                                                          )
                                                        }
                                                        data-type="plus"
                                                        data-field=""
                                                      >
                                                        <i className="fa fa-plus"></i>
                                                      </button>
                                                    </span>

                                                    <span className="input-group-prepend items-i"> {symbol} {' '} {subtotal}
                                                    </span>  
                                                 
                                                    
                                                  </div>
                                                  
                                                </div>

                                                

                                              </div>
                                              
                                            </div>
                                          </div>
                                        </div>
                                        //)
                                      }
                                    </div>
                                  );
                               
                                })}
                              </ul>
                            </div>
                          </div>

                          <div className="checkout-details ref-checkout-details ver-delivery">
                            <div className="order-box checkout-order-box chk-ref-total">
                              <ul className="">
                                <li>
                                  <label>Envío: </label>{' '} 
                                  <span className='text-delivery'>
                                  
                                  </span>
                                  <span className="">
                                    {symbol}
                                    {parseFloat(
                                      this.state.cost_delivery
                                    ).toFixed(0)}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                          
                          <div className="row chk-cart-buttons">
  {this.state.cash > 0 && (
    <div
      className="col-12 cart-btn-checkout cart-btn-checkout btn-chk-order"
      style={{ textAlign: "center" }}
    >
      <div className="qty-box quanty-categ-prod categ-prod-buttons">
        <div
          className="row check-out row-check-date"
          style={{ textAlign: "center", margin: "0 auto" }}
        >
          {/* Créditos disponibles */}
          <div className="form-group col-md-6 col-sm-6 col-xs-6 apply-credit-container">
            <span className="ver-btn-solid-credit btn">
              ¿Usas créditos?
              <div className="chk-credit">
                <span>Crédito actual: </span>
                <span>
                  {symbol} {this.state.cash}
                </span>
              </div>
            </span>
          </div>

          {/* Input para ingresar crédito */}
          {this.state.show_input_cash && (
            <div
              className="col-12 div-input-credit"
              style={{ textAlign: "center" }}
            >
              <div className="form-group apply-credit-container">
                <input
                  className="input-apply-credit"
                  type="text"
                  name="cash"
                  value={this.state.cash_amount > 0 ? this.state.cash_amount : ""}
                  onChange={this.onChangeCash}
                  placeholder="Ingresa crédito a descontar"
                />
                {this.state.errors.cash && (
                  <div
                    className="alert alert-danger ver-alert"
                    role="alert"
                  >
                    Su crédito disponible es {symbol} {this.state.cash}
                  </div>
                )}
              </div>
            </div>
          )}

          {/* Botón para aplicar crédito */}
          {this.state.show_input_cash && (
            <div className="col-12">
              <button
                type="button"
                className="btn-apply-credit"
                onClick={this.show_input}
              >
                APLICAR
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  )}

  {/* Mostrar el crédito usado después de aplicar */}
  {this.state.show_used_credit && (
    <div className="col-12 checkout-details ref-checkout-details ver-delivery">
      <div className="order-box checkout-order-box chk-ref-total">
        <ul>
          <li>
            <label>Crédito usado: </label>{' '}
            <span className="text-delivery"></span>
            <span>
              {symbol}
              {parseFloat(this.state.cash_amount || 0).toFixed(0)}
            </span>
          </li>
        </ul>
      </div>
    </div>
  )}
</div>


<div className="checkout-details ref-checkout-details ref-checkout-total">
  <div className="order-box checkout-order-box chk-ref-total">
    <ul className="total">
      <li>
        <label>Total:</label>{' '}
        <span className="count user-orders-total">
          {symbol}
          {parseFloat(this.state.total_order_visual).toFixed(2)}
        </span>
      </li>
    </ul>
  </div>
</div>


                          
                        </div>
                      }

<div className="container container_YaTransf_SubirVouch">

<div className="container row__alert_credito">
    <div className="col-12 col__alert_credito">
    {
    this.state.errors.cash === true?
    <div className="alert alert-danger ver-alert" role="alert" >Su crédito disponible es {symbol} {this.state.cash}</div>
    :''}
    </div>
</div>

{/* nuevo botón para modal Subir voucher */}
<div className="row">
  <div className="col-12 col_lbl__yaTransfer">
    <label className="lbl__yaTransfer">¿Ya transferiste?</label>
  </div>

  <div className="col-12 col_btn__subirVouch">
    <button type="button" className="btn btn-link btn__subirVoucher" onClick={this.handleShowModal}>
      {symbolUpload} Subir voucher
    </button>
  </div>
</div>

{/* Componente UploadVoucher */}
<UploadVoucher
  showOverlay={this.state.showModal}  
  setShowOverlay={this.handleCloseModal}  
  handleVoucherSubmit={this.handleVoucherSubmit}  
/>
{/* end - nuevo botón para modal Subir voucher */}

<div className="row">
    <div className="col-3">
        <hr className="divider_l" />
    </div>
    <div className="col-6 col_lbl_o_tambien_pued">
        <label className="lbl__o_tambien_pued">o, tambien puedes</label>
    </div>
    <div className="col-3">
        <hr className="divider_r" />
    </div>
</div>

<div className="row">
        <div className="col-12 form-check">
            <input
                type="checkbox"
                className="form-check-input"
                id="diasradio"
                checked={this.state.isCheckedDeferredPay}
                onChange={this.handleCheckboxChange}
            />
            <label className="form-check-label" htmlFor="diasradio">
                Seleccionar aquí y pagar en un par de días
            </label>
        </div>
        <input type="hidden" value={this.state.deferred_pay} id="deferred_pay" />
        
        
</div>
</div>

<div className="container chk-cart-buttons">
                            
                            
                            <div className="col-12 cart-btn-checkout">
                              <button
                                type="button"
                                className="btn btn-solid btn-referred-checkout"
                                disabled={this.state.loading}
                                onClick={() => this.handlePurchase()}
                              >
                                COMPRAR
                              </button>
                            </div>
                            <div className="col-12 cart-btn-keep-shopping">
                              <Link
                                to={`${process.env.PUBLIC_URL}/welcome`}
                                className=""
                              >
                                Seguir comprando
                              </Link>
                            </div>
                          </div>

                    </div>
                    
                  </form>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <VerThanks cash={this.state.cash} cash_win={this.state.cash_win} />
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  cartItems: state.cartList.cart,
  symbol: state.data.symbol,
  total: getCartTotal(state.cartList.cart),
});

export default connect(mapStateToProps, {
  removeFromWishlist,
  removeToCart,
  removeFromCart,
  incrementQty,
  decrementQty,
})(UserCheckOut);
